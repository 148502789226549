import { mq } from "@/constants/breakpoints";
import useReviewSitesData from "@/hooks/useReviewSitesData";
import { css } from "@emotion/react";
import Link from "next/link";
import { Body, BodyMedium14Css } from "../Typography";
import Image from "next/image";

interface FooterReviewButtonProps {
  type: "g2" | "trustpilot";
}

const FooterReviewButton = ({ type }: FooterReviewButtonProps) => {
  const { logo, label, href } = useReviewSitesData(type);
  return (
    <Link
      href={href}
      css={css`
        display: flex;
        gap: 15px;
        align-items: center;
        background-color: #1d313f;
        padding: 14px 16px;
        border-radius: 8px;
        transition: background-color 0.2s ease-in-out;
        width: 132px;
        &:hover {
          background-color: #203645;
        }
      `}
    >
      <Image src={logo} alt={`${label} logo`} width="30" height="30" />
      <Body
        css={css`
          font-size: 12px;
          color: #fff;
          font-weight: 500;
          line-height: 136%;
          ${mq["lg"]} {
            ${BodyMedium14Css}
          }
        `}
      >
        {label}
      </Body>
    </Link>
  );
};

export default FooterReviewButton;
