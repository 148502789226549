import { useMemo } from "react";
import g2Logo from "/public/images/review/g2-logo.svg";
import capterraLogo from "/public/images/review/capterra-logo.svg";
import trustpilotLogo from "/public/images/review/trustpilot-logo.svg";
import { css } from "@emotion/react";
import RatingStar from "@/svg/RatingStar";

const Stars = ({
  rating,
  type,
}: {
  rating: number;
  type: "g2" | "trustpilot" | "capterra";
}) => {
  let fillPercentage = rating * 11 + 10;
  if (rating == 5) {
    fillPercentage = 100;
  }

  return (
    <div css={css({ display: "flex", gap: 5, alignItems: "center" })}>
      <RatingStar uniqueId={`${type}-1`} />
      <RatingStar uniqueId={`${type}-2`} />
      <RatingStar uniqueId={`${type}-3`} />
      <RatingStar uniqueId={`${type}-4`} />
      <RatingStar uniqueId={`${type}-5`} fillPercentage={fillPercentage} />
    </div>
  );
};

const useReviewSitesData = (type: "g2" | "trustpilot" | "capterra") => {
  return useMemo(() => {
    if (type === "g2") {
      const rating = parseFloat(process.env.g2Rating || "0");
      return {
        logo: g2Logo,
        rating: {
          value: rating,
          stars: <Stars rating={rating} type="g2" />,
        },
        label: `${process.env.g2Count}+ reviews`,
        href: "https://www.g2.com/products/jersey-watch/reviews",
      };
    }
    if (type === "trustpilot") {
      const rating = parseFloat(process.env.trustpilotRating || "0");
      return {
        logo: trustpilotLogo,
        rating: {
          value: rating,
          stars: <Stars rating={rating} type="trustpilot" />,
        },
        label: `${process.env.trustpilotCount}+ reviews`,
        href: "https://www.trustpilot.com/review/jerseywatch.com",
      };
    } else {
      const rating = parseFloat(process.env.capterraRating || "0");
      return {
        logo: capterraLogo,
        rating: {
          value: rating,
          stars: <Stars rating={rating} type="capterra" />,
        },
        label: `${process.env.capterraCount}+ reviews`,
        href: "",
      };
    }
  }, [type]);
};

export default useReviewSitesData;
