import {
  blueColor,
  darkColor,
  lightBlueColor,
  textColor,
} from "@/constants/colors";
import navbarData from "@/constants/navbarData";
import { css } from "@emotion/react";
import Link from "next/link";
import { Tooltip } from "react-tooltip";
import { Body, H8_16 } from "../Typography";

interface NavbarDesktopFolderTooltipFeaturesProps {}

const NavbarDesktopFolderTooltipFeatures =
  ({}: NavbarDesktopFolderTooltipFeaturesProps) => {
    return (
      <Tooltip
        id="features-tooltip"
        place="bottom"
        opacity={1}
        clickable
        noArrow
        delayHide={0}
        delayShow={0}
        offset={0}
        css={css`
          z-index: 2;
          left: 50% !important;
          transform: translateX(-50%);
          background: transparent !important;
          padding: 30px;
          border-radius: 15px !important;
          background-color: #fff !important;
          display: grid;
          grid-row-gap: 5px;
          grid-column-gap: 14px;
          grid-template-columns: repeat(3, 1fr);
          transition: opacity 0.2s ease-in-out;
          box-shadow: 0px 8px 30px rgba(116, 134, 196, 0.24);
        `}
      >
        {navbarData.features.map(
          ({ title, description, Icon, href }, index) => {
            return (
              <Link href={href} key={index}>
                <div
                  css={css`
                    display: flex;
                    gap: 14px;
                    align-items: center;
                    padding: 12px 24px;
                    border: 2px solid transparent;
                    transition: border-color 0.2s ease-in-out;
                    border-radius: 10px;
                    &:hover {
                      border-color: ${blueColor};
                    }
                  `}
                >
                  <div
                    css={css`
                      display: flex;
                      width: 50px;
                      height: 50px;
                      background-color: ${blueColor};
                      align-items: center;
                      justify-content: center;
                      border-radius: 100%;
                    `}
                  >
                    <Icon
                      css={css`
                        width: 24px;
                        height: 24px;
                        color: ${lightBlueColor};
                      `}
                    />
                  </div>
                  <div>
                    <H8_16
                      css={css`
                        color: ${darkColor};
                      `}
                    >
                      {title}
                    </H8_16>
                    <Body
                      css={css`
                        font-size: 12px;
                        line-height: 140%;
                        margin-top: 2px;
                        color: ${textColor};
                      `}
                    >
                      {description}
                    </Body>
                  </div>
                </div>
              </Link>
            );
          }
        )}
      </Tooltip>
    );
  };

export default NavbarDesktopFolderTooltipFeatures;
