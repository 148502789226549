import { HTMLAttributes } from "react";

interface RatingStarProps extends HTMLAttributes<SVGElement> {
  fillPercentage?: number;
  uniqueId: string;
}

const RatingStar = ({
  fillPercentage = 100,
  uniqueId,
  ...props
}: RatingStarProps) => {
  return (
    <svg
      role="img"
      title="star rating"
      width="13"
      height="14"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient id={`starGradient-${uniqueId}`}>
          <stop offset={`${fillPercentage}%`} stopColor="#4EDFFF" />
          <stop offset={`${fillPercentage}%`} stopColor="transparent" />
        </linearGradient>
      </defs>
      <path
        d="M7.01025 10.6712L6.75497 11.1011L7.01025 10.6712C6.69553 10.4843 6.30386 10.4843 5.98913 10.6712L6.24441 11.1011L5.98913 10.6712L2.83434 12.5445L3.64104 8.96521C3.72151 8.60814 3.60048 8.23563 3.32549 7.99406L0.569033 5.57255L4.22239 5.23371C4.58685 5.19991 4.90372 4.96969 5.04849 4.63351L6.49969 1.26367L7.9509 4.63351C8.09567 4.96969 8.41254 5.19991 8.777 5.23371L12.4304 5.57255L9.67389 7.99406C9.39891 8.23563 9.27787 8.60814 9.35835 8.96521L10.165 12.5445L7.01025 10.6712Z"
        stroke="#4EDFFF"
        fill={`url(#starGradient-${uniqueId})`}
      />
    </svg>
  );
};

export default RatingStar;
