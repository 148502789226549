import { blueColor, darkColor } from "@/constants/colors";
import navbarData from "@/constants/navbarData";
import { pricingPath } from "@/constants/paths";
import { css } from "@emotion/react";
import Link from "next/link";
import { Tooltip } from "react-tooltip";
import { H8_16 } from "../Typography";

interface NavbarDesktopFolderTooltipResourcesProps {}

const NavbarDesktopFolderTooltipResources =
  ({}: NavbarDesktopFolderTooltipResourcesProps) => {
    return (
      <Tooltip
        id="resources-tooltip"
        place="bottom"
        noArrow
        delayHide={0}
        delayShow={0}
        offset={0}
        opacity={1}
        css={css`
          padding: 20px;
          z-index: 2;
          border-radius: 15px !important;
          background-color: #fff !important;
          box-shadow: 0px 8px 30px rgba(116, 134, 196, 0.24);
        `}
        clickable
      >
        {navbarData.resources.map(({ title, href }, index) => {
          if (href === pricingPath) return;
          return (
            <Link href={href} key={index}>
              <div
                css={css`
                  min-width: 200px;
                  padding: 12px 30px;
                  border: 2px solid transparent;
                  transition: border-color 0.2s ease-in-out;
                  border-radius: 10px;
                  &:hover {
                    border-color: ${blueColor};
                  }
                `}
              >
                <H8_16
                  css={css`
                    color: ${darkColor};
                  `}
                >
                  {title}
                </H8_16>
              </div>
            </Link>
          );
        })}
      </Tooltip>
    );
  };

export default NavbarDesktopFolderTooltipResources;
